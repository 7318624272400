import { useHistory } from "react-router-dom";
import { useModal } from "../contexts";
import useUser from "../hooks/useUser";
import PayWallModalPortantAI from "./PayWallModalPortantAI";

function PortantAILabel(props: { hidden?: boolean, full?: boolean }) {
  const { openModal } = useModal();
  const history = useHistory();
  const { user } = useUser();

  function handleOpen() {
    if (user?.subscription?.featurePortantAi)
      history.push("/settings/billing")
    else
      openModal(<PayWallModalPortantAI />);
  }

  if (props.hidden)
    return null;

  return (
    <button className="flex flex-shrink-0 items-center gap-1 bg-purple/20 px-2 rounded-sm text-purple font-gilroy font-semibold" onClick={handleOpen}>
      <img src="https://static.portant.co/magic-wand-icon-purple.svg" className="w-3 h-3" alt="Portant AI Icon" />Portant <span className="font-mono font-bold">AI</span>
    </button>
  )
}

export default PortantAILabel;
