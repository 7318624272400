import { useState } from "react";
import { useModal } from "../contexts";
import Modal from "./Modal";
import { getData, OAuthError, postData } from "../backend";
import useUser from "../hooks/useUser";
import OAuthModal from "./OAuthModal";
import ErrorModal from "./ErrorModal";
import SmallTextInputField from "./SmallTextInputField";
import SettingsTeamUserRole from "./SettingsTeamUserRole";
import { Team, TeamRole } from "../interfaces";
import { XIcon } from "@heroicons/react/outline";
import SettingsTeamUserModalAccessBatch from "./SettingsTeamUserModalAccessBatch";
import useSWR from "swr";

interface Invite {
  email: string;
  role: TeamRole;

}

function SettingsTeamModalInvite(props: { initialEmail?: string }) {
  const { closeModal, openModal } = useModal();

  const { user, refreshUser } = useUser();
  const { mutate: revalidateTeam } = useSWR<Team>("/auth/team/", getData);
  const team = user!.activeTeam!;

  const [invites, setInvites] = useState<Array<Invite>>([{ email: props.initialEmail ?? "", role: "EDITOR" }]);
  const invalidInvites = invites.map((invite, i) => validateEmail(invite.email) ? -1 : i).filter(i => i !== -1);

  const [showInvalidEmails, setShowInvalidEmails] = useState(false);
  const [isInviting, setIsInviting] = useState(false);

  function validateEmail(email: string) {
    if (!/[a-zA-Z0-9._+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9.-]{2,}/.test(email))
      return false;

    if (invites.filter(invite => invite.email === email).length > 1)
      return false; // duplicates

    if (team.members.filter(user => user.email === email).length > 0)
      return false; // existing user

    return true;
  }

  function handleUpdateInvite(index: number, updates: Partial<Invite>) {
    const newInvites = [...invites];
    newInvites[index] = { ...newInvites[index], ...updates };

    setInvites(newInvites);
  }

  async function handleInvite() {
    if (invalidInvites.length > 0)
      return setShowInvalidEmails(true);

    setIsInviting(true);

    const newTeamMembers = []

    for (const invite of invites) {
      try {
        const teamUser = await postData("/auth/team/invite/", invite);
        newTeamMembers.push(teamUser);
      } catch (error) {
        setIsInviting(false);

        if (error instanceof OAuthError)
          return openModal(<OAuthModal oauthUrl={error.oauthUrl} />)

        return openModal(<ErrorModal details={error} />)
      }
    }

    await refreshUser();
    await revalidateTeam();
    openModal(<SettingsTeamUserModalAccessBatch />);
  }

  return (
    <Modal
      title={
        <p className="font-gilroy font-semibold text-lg">
          Invite Team Members <span className="font-gilroy font-semibold text-gray-600 text-sm">({user?.activeTeam?.members.length}/{user?.activeTeam?.seats})</span>
        </p>
      }
      subtitle="Your team workflows and files will be shared with the permissions set by the role selected"
      secondaryButtonProps={{
        text: "Cancel", onClick: () => closeModal()
      }}
      primaryButtonProps={{
        onClick: handleInvite,
        disabled: showInvalidEmails && invalidInvites.length > 0,
        text: <>
          {isInviting ? "Inviting..." : "Invite"}
          {invites.length > 1 &&
            <div>({invites.length})</div>
          }
        </>
      }}

    >
      <div className="flex items-start gap-2 mt-4 w-full border-b">
        <div className="font-gilroy font-semibold text-gray-600 text-sm w-8/12">
          Email
        </div>
        <div className="font-gilroy font-semibold text-gray-600 text-sm w-3/12">
          Role
        </div>
      </div>

      <div className="flex flex-col gap-2 flex-grow py-2 w-full">
        {invites.map((invite, i) =>
          <div key={i} className="flex items-center gap-2 w-full">
            <div className="w-8/12">
              <SmallTextInputField value={invite.email} onChange={(email) => handleUpdateInvite(i, { email: email.toLowerCase() })} placeholder="Email" invalid={showInvalidEmails && invalidInvites.includes(i)} autoFocus />
            </div>

            <div className="flex flex-col w-3/12">
              <SettingsTeamUserRole role={invite.role} onSelectRole={(role) => handleUpdateInvite(i, { role })} />
            </div>

            {i > 0 &&
              <XIcon className="w-6 h-6 text-gray-400 hover:text-red cursor-pointer" onClick={() => setInvites(invites.filter((_, j) => j !== i))} />
            }
          </div>
        )}

        <div className="flex items-center gap-4">
          <button className="btn btn-white text-sm py-1 px-2 w-40 flex-shrink-0" onClick={() => setInvites([...invites, { email: "", role: "EDITOR" }])} disabled={(team.members.length + invites.length) >= team.seats}>
            Add another member
          </button>

          {/* NOTE: This currently wont be displayed */}
          {(team.members.length + invites.length) >= (team.seats + 1) &&
            <div className="text-xs font-semibold text-gray-400">
              You have reached team capacity. Please contact support if you would like to add more seats.
            </div>
          }
        </div>

      </div>
    </Modal>
  );
}

export default SettingsTeamModalInvite;
