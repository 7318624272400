import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BLOCK_ICON_MAP, BLOCK_NAME_MAP } from "../utils";
import { CheckCircleIcon, ExclamationIcon, PlusCircleIcon, RefreshIcon, SearchIcon, XIcon } from "@heroicons/react/outline";
import { useModal } from "../contexts";
import { ChatGPTField, Document, SourceField, Workflow } from "../interfaces";
import { BadRequestError, OAuthError, postData, putData } from "../backend";
import WorkflowSourceFieldTableRow from "./WorkflowSourceFieldTableRow";
import WorkflowChatGPTFieldTableRow from "./WorkflowChatGPTFieldTableRow";
import WorkflowChatGPTModal from "./WorkflowChatGPTModal";
import PortantAILabel from "./PortantAILabel";
import useUser from "../hooks/useUser";
import PayWallModalPortantAI from "./PayWallModalPortantAI";
import WorkflowSourceFieldTableHubSpot from "./WorkflowSourceFieldTableHubSpot";
import { KeyedMutator } from "swr";
import OAuthModal from "./OAuthModal";
import InaccessibleFilesModal from "./InaccessibleFilesModal";
import SmallTextInputField from "./SmallTextInputField";

interface Props {
  workflow: Workflow;
  mutateWorkflow: KeyedMutator<Workflow>;
  document?: Document;
  showRefreshSpinning?: boolean; // Override isRefreshing **only visually** to show the refresh button as spinning
  hideChatGPTField?: boolean;
  indexChatGPTField?: number;
  disableCopy?: boolean;
}

function WorkflowSourceFieldTable(props: Props) {
  const { user } = useUser();
  const { openModal } = useModal();

  const { workflow, mutateWorkflow } = props;
  const source = workflow.source!

  const tableRef = useRef<HTMLDivElement>(null);
  const [sourceSearch, setSourceSearch] = useState({ show: false, value: '' });
  const [openField, setOpenField] = useState<string | null>(null);

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [missingFields, setMissingFields] = useState<Array<string>>();

  const [dataGroupingField, setDataGroupingField] = useState<string | undefined>();

  useEffect(() => {
    if (source.sourceType === "MICROSOFT_EXCEL")
      setDataGroupingField(source.microsoftExcelConfig?.dataGroupingField || undefined);
    else
      setDataGroupingField(source.config.dataGroupingField);
  }, [source])

  const refresh = useCallback(async () => {
    setIsRefreshing(true);

    try {
      // TODO: Enable source refresh as an option depending on context of source field table
      await postData(`/workflows/sources/${workflow.source?.id}/refresh/`)
        .then(() => mutateWorkflow());

      await postData(`/workflows/${workflow.id}/check/`)
        .then(() => setMissingFields([])); // No error means no missing fields

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />)

      else if (error instanceof BadRequestError) {
        if (error.reason === "MISSING_FIELDS") {
          setMissingFields(error.payload.missingFields);

        } else if (error.reason === "INACCESSIBLE_FILES") {
          openModal(<InaccessibleFilesModal {...error.payload} />);

        }
      }
    };

    setIsRefreshing(false);
    mutateWorkflow();

  }, [workflow.id, workflow.source?.id, mutateWorkflow, openModal]);

  function scrollToFit(rowEl: HTMLDivElement) {
    const tableEl = tableRef.current!
    const tableHeight = tableEl.offsetHeight;
    const tableScrollTop = tableEl.scrollTop;

    const rowTop = rowEl.offsetTop - tableEl.offsetTop;
    const rowHeight = rowEl.clientHeight;
    const rowBottom = rowTop + rowHeight;

    if (rowTop < tableScrollTop) {
      tableEl.scroll({ top: rowTop, behavior: "smooth" });

    } else if (rowBottom > tableHeight) {
      const newScrollTop = rowTop - (300 - rowHeight) + 12; // + buffer to show next scrollable row
      tableEl.scroll({ top: newScrollTop, behavior: "smooth" });
    }
  }

  const updateDataGroupingField = useCallback((selectedField?: string) => {
    const updatedField = dataGroupingField === selectedField ? undefined : selectedField;
    setDataGroupingField(updatedField);

    if (source.sourceType === "MICROSOFT_EXCEL")
      putData(`/microsoft/config/${source.microsoftExcelConfig?.id}/`, { dataGroupingField: updatedField || "" })
        .then(() => mutateWorkflow())
        .catch(console.error);
    else
      putData(`/workflows/sources/${source.id}/`, { ...source, config: { ...source.config, dataGroupingField: updatedField } })
        .then(() => mutateWorkflow())
        .catch(console.error);
  }, [dataGroupingField, source, mutateWorkflow]);

  const handleSearchIcon = () => {
    setSourceSearch(prev => ({ ...prev, value: prev.show ? prev.value : '', show: !prev.show }));
  }

  function handleAddChatGPTField() {
    if (!user || (!user.subscription?.featurePortantAi && user?.aiUsage >= 10))
      return openModal(<PayWallModalPortantAI />);

    openModal(<WorkflowChatGPTModal workflow={workflow} mutateWorkflow={mutateWorkflow} />);
  }

  const fieldsStatus = (() => {
    if (missingFields === undefined)
      return null;

    if (missingFields.length === 0)
      return (
        <div className="flex flex-col text-sm p-2 border-t">
          <div className="flex items-center">
            <CheckCircleIcon className="w-4 h-4 mr-2 text-green" />All inserted document tags are valid
          </div>
        </div>
      );

    return (
      <div className="flex flex-col text-sm p-2 border-t">
        <div className="flex items-center">
          <ExclamationIcon className="w-4 h-4 mr-2 text-yellow" />This document contains invalid tags <RefreshIcon className={`h-4 w-4 ml-auto text-blue cursor-pointer ${isRefreshing ? "animate-spin" : "animate-none"}`} onClick={refresh} />
        </div>
        <ul className="list-disc ml-10">
          {missingFields.map(field => <li key={field}>{field}</li>)}
        </ul>
      </div>
    );

  })();

  const rows = useMemo(() => source.sourceFields
    .filter((field: SourceField) => !!field.fieldName && field.fieldType !== "OUTPUT" && field.fieldName.toLowerCase().includes(sourceSearch.value.toLowerCase()))
    .map((field: SourceField) => <WorkflowSourceFieldTableRow key={field.id} field={field} document={props.document} isOpen={openField === field.id} setOpenField={setOpenField} scrollToFit={scrollToFit} dataGroupingField={dataGroupingField} updateDataGroupingField={updateDataGroupingField} workflow={workflow} disableCopy={props.disableCopy} />
    ), [sourceSearch, openField, dataGroupingField, props.document, props.disableCopy, workflow, source, updateDataGroupingField]);

  const chatGPTRows = source.chatgptFields
    .filter((field: ChatGPTField) => props.indexChatGPTField === undefined || field.index < props.indexChatGPTField)
    .map((field: ChatGPTField) => <WorkflowChatGPTFieldTableRow key={field.id} field={field} document={props.document} disableCopy={props.disableCopy} />);

  let fileName = <span className="font-gilroy font-semibold ml-1 mt-1">Data Source</span>;

  if (source.file)
    fileName = <a className="font-gilroy font-semibold ml-1 mt-1 text-blue underline whitespace-nowrap overflow-ellipsis overflow-hidden w-[300px]" href={source.file.url.replace("/viewform", "/edit")} target="_blank" rel="noreferrer">{source.file.name}</a>

  if (source.microsoftExcelConfig?.fileId)
    fileName = <a className="font-gilroy font-semibold ml-1 mt-1 text-blue underline whitespace-nowrap overflow-ellipsis overflow-hidden w-[300px]" href={source.microsoftExcelConfig.fileUrl} target="_blank" rel="noreferrer">{source.microsoftExcelConfig.fileName}</a>

  else if (source.webhookConfig)
    fileName = source.webhookConfig.originUrl
      ? <a className="font-gilroy font-semibold ml-1 mt-1 text-blue underline whitespace-nowrap overflow-ellipsis overflow-hidden w-[300px]" href={source.webhookConfig.originUrl} target="_blank" rel="noreferrer">{source.webhookConfig.name || `${BLOCK_NAME_MAP[source.sourceType] ?? ""} Source`}</a>
      : <span className="font-gilroy font-semibold ml-1 mt-1 text-gray-600 whitespace-nowrap overflow-ellipsis overflow-hidden w-[300px]">{source.webhookConfig.name || `${BLOCK_NAME_MAP[source.sourceType] ?? ""} Source`}</span>

  if (source.sourceType === "HUBSPOT")
    return <WorkflowSourceFieldTableHubSpot {...props} />;

  return (
    <div className="flex flex-col border border-gray-400 rounded-sm" >
      <div className="border-b border-gray-400">
        <div className="flex items-center text-sm">
          <img className="w-4 h-4 ml-2 my-2" src={BLOCK_ICON_MAP[source.sourceType]} alt="Data Source Icon" />
          {fileName}
          <SearchIcon className={`h-6 w-6 ml-auto mr-2 text-blue cursor-pointer`} onClick={handleSearchIcon} />
          <RefreshIcon className={`h-6 w-6 ml-auto mr-2 text-blue cursor-pointer ${isRefreshing || props.showRefreshSpinning ? "animate-spin" : "animate-none"}`} onClick={refresh} />
        </div>
        <div className={`px-2 flex items-center transition-all relative overflow-hidden ${sourceSearch.show ? 'h-12' : 'h-0'}`}>
          {sourceSearch.show &&
            <SmallTextInputField className="w-full" placeholder="Filter fields..." value={sourceSearch.value} onChange={(newValue: string) => setSourceSearch(prev => ({ ...prev, value: newValue }))} autoFocus />
          }
          {sourceSearch.value !== '' && <XIcon className="absolute right-3 top-3 h-6 w-6 text-gray-400 bg-white cursor-pointer" onClick={() => setSourceSearch({ value: '', show: false })} />}
        </div>
      </div>
      <div ref={tableRef} className="overflow-y-scroll" style={{ maxHeight: "300px" }}>
        {rows}
        {rows.length === 0 && (
          <div className="text-xs text-gray-400 text-center p-2">
            No fields found.<br />
            Please ensure the source contains valid fields then refresh the table{sourceSearch.value ? ', or try disabling the filtering by clicking the search icon again' : ''}.
          </div>
        )}
      </div>
      <div className="overflow-y-scroll border-t" style={{ maxHeight: "210px" }}>
        {!props.hideChatGPTField &&
          <div className="flex justify-center py-2 px-2 text-sm border-b last:border-b-0 items-center gap-1 text-blue">
            {/* <img src="https://static.portant.co/openai-icon.svg" className="w-4 h-4" alt="OpenAI Icon" /> */}
            <PlusCircleIcon className="w-4 h-4" />
            <div className="flex items-center gap-1 font-semibold font-gilroy cursor-pointer hover:underline" onClick={handleAddChatGPTField}>
              {/* Add ChatGPT Field */}
              Add AI Content Field
            </div>
            <PortantAILabel />
          </div>
        }
        {chatGPTRows}
      </div>
      {fieldsStatus}
    </div>
  );
};

export default WorkflowSourceFieldTable;
