import moment from "moment-timezone";
import { useState } from "react";
import { useModal, WorkflowContextData } from "../contexts";
import { OAuthError, patchData, postData, putData } from "../backend";
import { Source, Document, HubspotPrimaryObject } from "../interfaces";
import WorkflowSelectButton from "./WorkflowSelectButton";
import WorkflowSelectGoogleButton from "./WorkflowSelectGoogleButton";
import WorkflowSelectMicrosoftButton, { PickerResponse as MicrosoftPickerResponse } from "./WorkflowSelectMicrosoftButton";
import Modal from "./Modal";
import LoadingModal from "./LoadingModal";
import ErrorModal from "./ErrorModal";
import OAuthModal from "./OAuthModal";
import OAuthModalHubspot from "./OAuthModalHubspot";
import OAuthModalXero from "./OAuthModalXero";
import OAuthModalMicrosoft from "./OAuthModalMicrosoft";
import { useHistory } from "react-router-dom";
import OAuthModalCopper from "./OAuthModalCopper";
import WorkflowSelectSheetsTemplateButton from "./WorkflowSelectSheetsTemplateButton";
import { ChevronRightIcon } from "@heroicons/react/outline";
import useUser from "../hooks/useUser";
import WorkflowSelectPDFButton from "./WorkflowSelectPDFButton";
import WorkflowSelectHubspotButton from "./WorkflowSelectHubspotButton";

interface Props {
  title: string;
  subtitle: string;
  showSources?: boolean;
  showDocuments?: boolean;
  showEmails?: boolean;
  showRecordOutputs?: boolean;
  showReview?: boolean;
  showSignatureRequest?: boolean;
  documentPositionFirst?: boolean;
  onSelection?: (stage: "SOURCE" | "DOCUMENT", blockType: string, file?: any, isPdf?: boolean, options?: any) => void;
  workflowContext?: WorkflowContextData;
}

function WorkflowSelectModal(props: Props) {
  const { user } = useUser();
  const { openModal, closeModal } = useModal();

  const { workflowContext } = props;
  const workflow = workflowContext?.workflow;
  const mutateWorkflow = workflowContext?.mutateWorkflow;

  const [showAdvancedTemplates, setShowAdvancedTemplates] = useState<boolean>(false);

  const history = useHistory();

  const [openBlock, setOpenBlock] = useState<string | null>(null);

  const navigate = true;

  async function addWorkflowSource(sourceType: string, file?: any, options?: Record<string, any>) {
    props.onSelection?.("SOURCE", sourceType, file, undefined, options);
    if (!workflow || !mutateWorkflow)
      return;

    const subtitle = !!file
      ? "This may take a minute as we retrieve the file from your Drive."
      : "This may take a minute as we create a new file in your Drive.";

    openModal(<LoadingModal title="Adding new Block" subtitle={subtitle} />)

    try {
      const sourceOptions = { sourceType, ...(options ?? {}) };
      const source: Source = await postData(`/workflows/${workflow!.id}/source/`, sourceOptions);

      if (sourceType === "MICROSOFT_EXCEL" && file)
        await putData(`/microsoft/config/${source.microsoftExcelConfig!.id}/`, { fileId: file.id });

      else if (file)
        source.file = await putData(`/workflows/sources/${source.id}/file/`, { file });

      else if (["GOOGLE_SHEETS", "GOOGLE_FORMS"].includes(sourceType)) // Create file only for Google source types
        source.file = await postData(`/workflows/sources/${source.id}/file/`);

      if (sourceType === "GOOGLE_FORMS") // Enable auto-create
        putData(`/workflows/${workflow.id}/auto-create/`, { autoCreate: true });

      await mutateWorkflow();

      if (navigate)
        history.push("/w/" + workflow.id + (sourceType === "SIGNATURE_LINK" ? "/document" : "/source"));

      closeModal();

      //@ts-ignore
      const dataLayer = window.dataLayer;
      dataLayer.push({ "event": "add_source", 'source_type': sourceType });

    } catch (error) {
      if (error instanceof OAuthError)
        if (error.oauthUrl.includes("app.hubspot"))
          openModal(<OAuthModalHubspot oauthUrl={error.oauthUrl} />);
        else if (error.oauthUrl.includes("xero"))
          openModal(<OAuthModalXero oauthUrl={error.oauthUrl} />);
        else
          openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  async function addWorkflowDocument(documentType: string, file?: any) {
    props.onSelection?.("DOCUMENT", documentType, file);
    if (!workflow || !mutateWorkflow)
      return;

    const subtitle = !!file
      ? "This may take a minute as we retreive the file from your Drive."
      : "This may take a minute as we create a new file in your Drive.";

    openModal(<LoadingModal title="Adding new Block" subtitle={subtitle} />)

    const floatOrder = (() => {
      const defaultValue = 16384; // 2 ^ 14

      if (workflow.documents.length === 0)
        return defaultValue;

      if (props.documentPositionFirst)
        return workflow.documents[0].floatOrder / 2;
      else
        return workflow.documents[workflow.documents.length - 1].floatOrder + defaultValue;
    })();

    try {
      const document: Document = await postData(`/workflows/${workflow!.id}/documents/`, { documentType, floatOrder });

      if (file)
        document.file = await putData(`/workflows/documents/${document.id}/file/`, { file });

      else if (documentType.startsWith("GOOGLE")) // Create file only for Google document types
        document.file = await postData(`/workflows/documents/${document.id}/file/`);

      await mutateWorkflow().then(workflow => {
        if (navigate && workflow) {
          const index = workflow.documents.findIndex(d => d.id === document.id) + 1;
          if (index > 1)
            history.push("/w/" + workflow.id + "/document#" + index);
          else
            history.push("/w/" + workflow.id + "/document");
        }
      })

      closeModal();

      //@ts-ignore
      const dataLayer = window.dataLayer;
      dataLayer.push({ "event": "add_document", 'document_type': documentType });

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  async function addWorkflowDocumentPDFFile(documentType: string, file: File) {
    props.onSelection?.("DOCUMENT", documentType, file, true);
    if (!workflow || !mutateWorkflow)
      return;

    openModal(<LoadingModal title="Adding new Block" subtitle="Uploading PDF file" />);

    const floatOrder = (() => {
      const defaultValue = 16384; // 2 ^ 14

      if (workflow.documents.length === 0)
        return defaultValue;

      if (props.documentPositionFirst)
        return workflow.documents[0].floatOrder / 2;
      else
        return workflow.documents[workflow.documents.length - 1].floatOrder + defaultValue;
    })();

    try {
      const document: Document = await postData(`/workflows/${workflow!.id}/documents/`, { documentType, floatOrder });

      const content = new Blob([file], { type: "application/pdf" });

      const { url } = await postData(`/workflows/documents/${document!.id}/generate-pdf-upload-url/`);

      await fetch(url, {
        method: "PUT",
        headers: {
          "content-type": "application/pdf"
        },
        body: content
      });

      await postData(`/workflows/documents/${document!.id}/confirm-pdf-upload-status/`, { filename: file.name });

      await mutateWorkflow().then(workflow => {
        if (navigate && workflow) {
          const index = workflow.documents.findIndex(d => d.id === document.id) + 1;
          if (index > 1)
            history.push("/w/" + workflow.id + "/document#" + index);
          else
            history.push("/w/" + workflow.id + "/document");
        }
      })


      closeModal();

      //@ts-ignore
      const dataLayer = window.dataLayer;
      dataLayer.push({ "event": "add_document", 'document_type': documentType });

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  async function addWorkflowSharing(emailType: string, file?: any) {
    if (!workflow || !mutateWorkflow)
      return;

    const subtitle = !!file
      ? "This may take a minute as we retreive the file from your Drive."
      : "This may take a minute as we create a new file in your Drive.";

    openModal(<LoadingModal title="Adding new Block" subtitle={subtitle} />)

    try {
      const email = await postData(`/workflows/${workflow!.id}/emails/`, { emailType });

      await mutateWorkflow().then(workflow => {
        if (navigate && workflow) {
          const index = workflow.emails.findIndex(e => e.id === email.id) + 1;
          if (index > 1)
            history.push("/w/" + workflow.id + "/email#" + index);
          else
            history.push("/w/" + workflow.id + "/email");
        }
      })

      closeModal();

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  async function addWorkflowSignatureRequest() {
    if (!workflow || !mutateWorkflow)
      return;

    openModal(<LoadingModal title="Adding new Block" subtitle={"Creating a signature request block"} />)

    try {
      await postData(`/workflows/${workflow!.id}/signature-config/`);
      await mutateWorkflow();

      history.push("/w/" + workflow.id + "/signature-request");
      closeModal();

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  async function addWorkflowWebhook() {
    if (!workflow || !mutateWorkflow)
      return;

    openModal(<LoadingModal title="Adding new Block" subtitle={"Creating a webhook block"} />)

    try {
      await postData(`/workflows/${workflow!.id}/webhook-config/`);
      await mutateWorkflow();

      // TODO: Open modal

      closeModal();

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  async function addWorkflowReview(reviewType: string, file?: any) {
    if (!workflow || !mutateWorkflow)
      return;

    const subtitle = !!file
      ? "This may take a minute as we retreive the file from your Drive."
      : "This may take a minute as we create a new file in your Drive.";

    openModal(<LoadingModal title="Adding new Block" subtitle={subtitle} />)

    try {
      await postData(`/workflows/${workflow!.id}/review/`, { reviewType });
      await mutateWorkflow();

      history.push("/w/" + workflow.id);
      closeModal();

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  async function addWorkflowSheetsWriteToSource(unusedBlockType: string, file?: any) {
    if (!workflow || !mutateWorkflow)
      return;

    const subtitle = !!file
      ? "This may take a minute as we retreive the file from your Drive."
      : "This may take a minute as we create a new file in your Drive.";

    openModal(<LoadingModal title="Adding new Block" subtitle={subtitle} />)

    try {
      let documentIds: string[] = [];

      workflow!.documents.forEach(document => {
        if (!document.removeOutput)
          documentIds.push(document.id);
        if (document.createPdfCopy)
          documentIds.push(document.id + "-pdf")
      });

      await patchData(`/workflows/sources/${workflow!.source!.id}/`, {
        config: {
          ...workflow!.source!.config,
          writeToSource: documentIds
        }
      });

      await mutateWorkflow();
      closeModal();

      history.push("/w/" + workflow.id);

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  async function addWorkflowGlideWriteToSource(unusedBlockType: string, file?: any) {
    if (!workflow || !mutateWorkflow)
      return;

    const subtitle = !!file
      ? "This may take a minute as we retreive the file from your Drive."
      : "This may take a minute as we create a new file in your Drive.";

    openModal(<LoadingModal title="Adding new Block" subtitle={subtitle} />)

    try {
      await patchData(`/glide/config/${workflow!.source!.glideConfig!.id}/`, { isSharingEnabled: true });

      await mutateWorkflow();
      closeModal();

      history.push("/w/" + workflow.id);

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  async function handleSelectHubspot(blockType: string, hubspotPrimaryObject: HubspotPrimaryObject) {
    openModal(<LoadingModal />);

    try {
      await postData("/hubspot/access-token/");
      addWorkflowSource("HUBSPOT", undefined, { hubspotPrimaryObject });

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModalHubspot oauthUrl={error.oauthUrl} onClose={() => handleSelectHubspot(blockType, hubspotPrimaryObject)} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  async function handleSelectCopper() {
    openModal(<LoadingModal />);

    try {
      await postData("/copper/access-token/");
      addWorkflowSource("COPPER");

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModalCopper oauthUrl={error.oauthUrl} onClose={handleSelectCopper} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  async function handleSelectXero() {
    openModal(<LoadingModal />);

    try {
      await postData("/xero/access-token/");
      addWorkflowSource("XERO");

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModalXero oauthUrl={error.oauthUrl} onClose={handleSelectXero} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  async function handleSelectMicrosoft(response: MicrosoftPickerResponse) {
    openModal(<LoadingModal />);

    try {
      await postData("/microsoft/access-token/");
      addWorkflowSource("MICROSOFT_EXCEL", response.value[0]);

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModalMicrosoft oauthUrl={error.oauthUrl} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  const showWriteToSheets = workflow?.source?.sourceType === "GOOGLE_SHEETS" && workflow.documents.length > 0 && !workflow.source.config.writeToSource;
  const showWriteToGlide = workflow?.source?.sourceType === "GLIDE" && workflow.documents.length > 0 && !workflow.source.glideConfig?.isSharingEnabled;
  const showSendWebhookEvents = !!workflow && (workflow.documents.length > 0 || workflow.emails.length > 0) && !workflow.webhookConfig;

  const showRecordOutputs = showWriteToSheets || showWriteToGlide || showSendWebhookEvents;

  return (
    <Modal
      title={props.title}
      subtitle={props.subtitle}
    >
      <div className="flex flex-col gap-4 overflow-y-auto overflow-x-visible max-h-[500px] pr-6 -mr-6">

        {props.showDocuments &&
          <div className="flex flex-col w-full gap-2">
            <div className="font-gilroy font-semibold text-gray-600">
              Templates
            </div>
            <WorkflowSelectGoogleButton blockType="GOOGLE_DOCS" title="Google Docs" subtitle="Create documents from a Google Docs template" onSelection={addWorkflowDocument} openBlock={openBlock} setOpenBlock={setOpenBlock} />
            <WorkflowSelectGoogleButton blockType="GOOGLE_SLIDES" title="Google Slides" subtitle="Create presentations from a Google Slides template" onSelection={addWorkflowDocument} openBlock={openBlock} setOpenBlock={setOpenBlock} />
            {(moment().format("DD/MM") === "01/04") &&
              <WorkflowSelectButton blockType="APRIL_FOOLS" title="Google Dogs" subtitle="Create documents with your favourite K-9" onSelection={() => alert("Happy April Fools")} />
            }
            {workflow?.source?.sourceType !== "SIGNATURE_LINK" &&
              <>
                <div className="flex text-sm font-semibold items-center hover:underline text-gray-400 hover:text-gray-600 cursor-pointer" onClick={() => setShowAdvancedTemplates(f => !f)}>
                  <ChevronRightIcon className={`w-4 h-4 mr-1 ${showAdvancedTemplates ? "rotate-90" : "rotate-0"}`} /> Advanced Templates
                </div>
                {showAdvancedTemplates &&
                  <div className="w-ful flex flex-col gap-2">
                    <WorkflowSelectPDFButton blockType="PDF_FILLABLE" title="Fillable PDF" subtitle="Merge data in a PDF template that contains form fields" onSelection={addWorkflowDocument} onSelectPdfFile={addWorkflowDocumentPDFFile} openBlock={openBlock} setOpenBlock={setOpenBlock} />
                    <WorkflowSelectSheetsTemplateButton
                      blockType="GOOGLE_SHEETS_MERGE"
                      title="Google Sheets"
                      subtitle="Create spreadsheets from a Google Sheets template"
                      onSelection={addWorkflowDocument}
                      openBlock={openBlock}
                      setOpenBlock={setOpenBlock}
                    />
                    {user?.subscription?.featureSheetsAppend &&
                      <WorkflowSelectSheetsTemplateButton
                        blockType="GOOGLE_SHEETS_APPEND"
                        title="Add rows to Google Sheets"
                        subtitle="Create new rows in the same Google Sheets file each time"
                        onSelection={addWorkflowDocument}
                        openBlock={openBlock}
                        setOpenBlock={setOpenBlock}
                      />
                    }
                  </div>
                }
              </>}
          </div>
        }

        {props.showSources &&
          <div className="flex flex-col w-full gap-2">
            <div className="font-gilroy font-semibold text-gray-600">
              Sources
            </div>
            <WorkflowSelectHubspotButton title="HubSpot" subtitle="Integrate with HubSpot to run automations on Deals, Contacts, and more" onSelection={handleSelectHubspot} />
            <WorkflowSelectGoogleButton blockType="GOOGLE_SHEETS" title="Google Sheets" subtitle="Create documents from rows in your spreadsheet" onSelection={addWorkflowSource} openBlock={openBlock} setOpenBlock={setOpenBlock} />
            <WorkflowSelectGoogleButton blockType="GOOGLE_FORMS" title="Google Forms" subtitle="Create documents from responses in your form" onSelection={addWorkflowSource} openBlock={openBlock} setOpenBlock={setOpenBlock} />
            <WorkflowSelectButton blockType="SIGNATURE_LINK" title="Signature Link" subtitle="Capture signatures directly via a shareable link" onSelection={addWorkflowSource} />
            <WorkflowSelectButton blockType="COPPER" title="Copper" subtitle="Integrate with Copper to run automations on opportunities" onSelection={handleSelectCopper} />
            <WorkflowSelectButton blockType="XERO" title="Xero" subtitle="Create documents from invoices in Xero" onSelection={handleSelectXero} />
            <WorkflowSelectMicrosoftButton blockType="MICROSOFT_EXCEL" title="Microsoft Excel" subtitle="Create documents from rows in your Excel spreadsheet" onSelection={handleSelectMicrosoft} />
            <WorkflowSelectButton blockType="TALLY" title="Tally" subtitle="Create documents when a response is submitted in Tally" onSelection={addWorkflowSource} />
            <WorkflowSelectButton blockType="GLIDE" title="Glide" subtitle="Integrate with Glide to create documents from Glide apps" onSelection={addWorkflowSource} />
            <WorkflowSelectButton blockType="WEBHOOK" title="Webhook" subtitle="Create documents from any incoming webhook event" onSelection={addWorkflowSource} />
            {/* TODO: Enable Zapier when listing is ready */}
            {/* <WorkflowSelectButton blockType="ZAPIER" title="Zapier" subtitle="Use a Portant workflow as an action in Zapier" onSelection={addWorkflowSource} /> */}
          </div>
        }

        {(props.showReview) &&
          <div className="flex flex-col w-full gap-2">
            <div className="font-gilroy font-semibold text-gray-600">
              Human in the Loop
            </div>
            <WorkflowSelectButton blockType="REVIEW" title="Review" subtitle="Review documents before creating PDFs or sending emails" onSelection={addWorkflowReview} pro hasProFeature={user?.subscription?.featureReviews ?? false} />
          </div>
        }

        {(props.showSignatureRequest) &&
          <div className="flex flex-col w-full gap-2">
            <div className="font-gilroy font-semibold text-gray-600">
              eSignatures
            </div>
            <WorkflowSelectButton blockType="SIGNATURE_REQUEST" title="Request Signatures" subtitle="Have documents signed via an email request" onSelection={addWorkflowSignatureRequest} />
          </div>
        }

        {props.showEmails &&
          <div className="flex flex-col w-full gap-2">
            <div className="font-gilroy font-semibold text-gray-600">
              Share by Email
            </div>
            <WorkflowSelectButton blockType="GMAIL" title="Gmail" subtitle="Send email or share documents via Gmail" onSelection={addWorkflowSharing} />
            <WorkflowSelectButton blockType="OUTLOOK" title="Microsoft Outlook" subtitle="Send email or share documents via Outlook" onSelection={addWorkflowSharing} beta />
          </div>
        }

        {(props.showRecordOutputs && showRecordOutputs) &&
          <div className="flex flex-col w-full gap-2">
            <div className="font-gilroy font-semibold text-gray-600">
              Record Outputs
            </div>
            {showWriteToSheets &&
              <WorkflowSelectButton blockType="WRITE_TO_SOURCE" title="Write Links to Source" subtitle="Save generated document links back to the source" onSelection={addWorkflowSheetsWriteToSource} />
            }
            {showWriteToGlide &&
              <WorkflowSelectButton blockType="GLIDE_SHARE" title="Glide" subtitle="Write back to your nominated Glide table" onSelection={addWorkflowGlideWriteToSource} />
            }
            {showSendWebhookEvents &&
              <WorkflowSelectButton blockType="WEBHOOK" title="Send Events to Webhook" subtitle="Notify an external webhook of workflow events" onSelection={addWorkflowWebhook} />
            }
          </div>
        }
      </div>
    </Modal>
  );
}

export default WorkflowSelectModal;
