import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { WorkflowContext, useModal } from "../contexts";
import TransitionWorkflowPage from "./transitions/TransitionWorkflowPage";
import WorkflowSourceSheets from "./WorkflowSourceSheets";
import WorkflowSourceForms from "./WorkflowSourceForms";
import WorkflowSourceHubspot from "./WorkflowSourceHubspot";
import WorkflowSourceTally from "./WorkflowSourceTally";
import WorkflowSourceZapier from "./WorkflowSourceZapier";
import WorkflowSourceWebhook from "./WorkflowSourceWebhook";
import WorkflowSourceGlide from "./WorkflowSourceGlide";
import WorkflowSourceXero from "./WorkflowSourceXero";
import WorkflowSourceMicrosoftExcel from "./WorkflowSourceMicrosoftExcel";
import WorkflowSelectModal from "./WorkflowSelectModal";
import WorkflowSourceCopper from "./WorkflowSourceCopper";
import WorkflowSourceSignatureLink from "./WorkflowSourceSignatureLink";


function WorkflowSource() {
  const { pathname } = useLocation();
  const { openModal } = useModal();

  const workflowContext = useContext(WorkflowContext);
  const { workflow } = workflowContext;

  const sourceComponentMap = {
    "GOOGLE_SHEETS": WorkflowSourceSheets,
    "GOOGLE_FORMS": WorkflowSourceForms,
    "HUBSPOT": WorkflowSourceHubspot,
    "TALLY": WorkflowSourceTally,
    "ZAPIER": WorkflowSourceZapier,
    "WEBHOOK": WorkflowSourceWebhook,
    "GLIDE": WorkflowSourceGlide,
    "XERO": WorkflowSourceXero,
    "MICROSOFT_EXCEL": WorkflowSourceMicrosoftExcel,
    "COPPER": WorkflowSourceCopper,
    "SIGNATURE_LINK": WorkflowSourceSignatureLink,
    "DEBUG_JSON": () => <div />,
  }

  if (!workflow.source) {
    if (!pathname.endsWith("/source"))
      return null;

    return (
      <div className="flex flex-col items-center justify-center gap-4 h-full w-full">
        <div className="font-semibold font-gilroy text-gray-600 text-center w-96">
          This workflow does not contain a signature request, would you like to add one?
        </div>
        <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Add Workflow Signature Request" subtitle="Add a signature request to your workflow" showSources workflowContext={workflowContext} />)}>
          Add Signature Request
        </button>
      </div>
    );
  }

  const SourceComponent = sourceComponentMap[workflow.source.sourceType]

  return (
    <TransitionWorkflowPage show={pathname.endsWith("/source")}>
      <SourceComponent />
    </TransitionWorkflowPage>
  );

}

export default WorkflowSource;
