import { useLayoutEffect, useState } from "react";
import useSWR from 'swr';
import packageJson from '../../package.json';
import { getData, postData } from "../backend";
import { StarIcon } from "@heroicons/react/outline";

function AppPortal() {

  const { data: apiVersionData, error: apiError } = useSWR("/version/", getData);
  const [showAuthProviderWarning, setShowAuthProviderError] = useState(false);

  useLayoutEffect(() => {
    // @ts-ignore
    const google = window.google;

    google.accounts.id.prompt();

    google.accounts.id.renderButton(
      document.getElementById("google-signin-button"),
      {
        "size": "large",
        "text": "signup_with",
        "theme": "filled_blue",
        "width": "300",
      }
    );
  }, []);

  function showDelayedAuthWarning() {
    setTimeout(() => setShowAuthProviderError(true), 5000);
  }

  async function loginWithMicrosoft() {
    showDelayedAuthWarning();

    try {
      const { url } = await postData("/auth/microsoft-login-oauth-url/");
      window.open(url, "_blank");
    } catch (error) { }
  }

  return (
    <div className="relative flex items-center w-screen h-screen">
      {apiError &&
        <div className="absolute top-0 left-0 flex justify-center w-full z-10">
          <div className="bg-yellow font-gilroy font-semibold text-white py-2 px-4 rounded-b">
            We are currently updating Portant. Please check back in a few minutes.
          </div>
        </div>
      }

      <div id="google-signin-prompt" />

      <div className="flex flex-col items-center justify-center relative h-full bg-white w-full flex-grow-0 p-8">
        <img className='absolute left-10 top-10 w-32' src='https://static.portant.co/logo-full.svg' alt='Portant Logo' />

        {showAuthProviderWarning &&
          <div className='relative top-20 text-gray-600 text-sm font-semibold mb-8 text-center border-2 border-yellow bg-yellow/10 rounded p-1'>
            Please ensure you signin with the same authentication method you used to signup. Please contact support if you need assistance.
          </div>
        }

        <div className="flex flex-col items-center h-[200px] my-auto">

          <div className="font-gilroy font-semibold text-gray-600 text-4xl mx-4 text-center">
            Start your free trial today
          </div>
          <div className="font-gilroy font-semibold text-gray-600 text-lg text-center">
            No credit card required
          </div>
          <div className="mt-8" />

          <div id="google-signin-button" />

          <button className="mt-2 hover:shadow" onClick={loginWithMicrosoft}>
            <img className="w-[200px]" src="https://static.portant.co/btn-microsoft-signin-light.svg" alt="Signin with Microsoft" />
          </button>

          <div className='text-gray-600 text-xs mt-8 flex-wrap'>
            By signing up you agree to our <a className='underline' href="https://www.portant.co/terms-and-conditions" target='_blank' rel="noreferrer">Terms</a> and <a className='underline' href="https://www.portant.co/privacy-policy" target='_blank' rel="noreferrer">Privacy Policy</a>
          </div>
        </div>

        <div className="absolute bottom-0 left-0 w-full flex items-end justify-between font-gilroy font-semibold text-xs text-gray-400 h-16 p-4">
          <div className="flex flex-col">
            <div>v{packageJson?.version}</div>
            <div>(v{apiVersionData?.version ?? "----"})</div>
          </div>
          <StarIcon className="w-4 h-4 text-white hover:text-yellow hover:animate-ping" />
        </div>
      </div>

      <div className="hidden md:flex flex-col h-full items-center justify-center from-blue to-[#8ceffc] bg-gradient-to-tr w-7/12 flex-shrink-0 p-8">

        <div className="flex flex-col items-center h-[200px]">
          <div className='text-white text-2xl text-center text-gilroy font-semibold'>
            Over 700k users like you have generated more<br /> than 2 million documents with Portant.
          </div>

          <div className="mt-auto" />
          <div className='flex items-center gap-10 mt-20 lg:flex-nowrap flex-wrap justify-center'>
            <img src="https://static.portant.co/disney-logo-white.png" className='h-8' alt="Disney Logo" />
            <img src="https://static.portant.co/spotify-logo-white.png" className='h-8' alt="Spotify Logo" />
            <img src="https://static.portant.co/netflix-logo-white.png" className='h-8' alt="Netflix Logo" />
            <img src="https://static.portant.co/uber-logo-white.png" className='h-8' alt="Uber Logo" />
          </div>
        </div>

      </div>
    </div>
  )
}

export default AppPortal;
